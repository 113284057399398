/* purgecss start ignore */
/*@ Swal notification style */
.swal2-container .swal2-popup.swal2-toast {
  flex-direction: row !important;
  justify-content: space-between !important;
  padding: 10px !important;
}

.change_role_class .swal2-validation-message {
  margin: 0px !important
}

/*@ Logo style */
.logo-wrapper img {
  height: 70px;
  object-fit: contain;
}

/*@ Tooltip */

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 140px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -75px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

/* Dropzone css starts*/
.vue-dropzone>.dz-preview .dz-details {
  opacity: 1 !important;
  position: relative !important;
  background-color: transparent !important;
  color: #000 !important;
}

#customdropzone .dz-preview:hover .dz-image img {
  filter: blur(0px) !important;
}

#customdropzone .dz-preview {
  /* width: 20%; */
  display: inline-block;
  margin: 0px !important;
}

#customdropzone .dz-preview .dz-image {
  width: 80px;
  height: 80px;
  margin-left: 40px;
  margin-bottom: 10px;
  margin: 0px auto;
}

#customdropzone .dz-preview .dz-image>div {
  width: inherit;
  height: inherit;
  border-radius: 50%;
  background-size: contain;
}

#customdropzone .dz-preview .dz-image>img {
  width: 100%;
}

#customdropzone .dz-preview .dz-details {
  color: white;
  transition: opacity 0.2s linear;
  text-align: center;
}

#customdropzone .dz-success-mark,
.dz-error-mark,
.dz-progress {
  display: none;
}

#customdropzone .dz-preview .dz-remove {
  position: relative !important;
  opacity: 1 !important;
  color: #000 !important;
  border: none !important;
  padding: 0px !important;
  margin: 0px !important;
}

/* Dropzone css ends*/

/*@ Start: Checkbox Input */

.container input:checked~.checkmark {
  background-color: #4F6B69;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.container input:checked~.checkmark:after {
  display: block;
}

.container .checkmark:after {
  left: 9px;
  top: 6px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/*@ End: Checkbox Input */

.max-h-table {
  max-height: 300px;
}

.register-checkbox {
  border-color: black;
}

.google-icon {
  background-image: url("/images/google.png");
}

.microsoft-icon {
  background-image: url("/images/microsoft.png");
}

.social-buttons {
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  background-position: 7px;
  display: inline-block;
}

.custom-class-mfa svg {
  display: unset;
}

.custom-class-mfa .swal2-icon {
  display: none !important;
}

.mfa_popup {
  display: block;
}

.manual-code {
  font-size: 16px;
}

.swal2-actions {
  z-index: 0 !important;
}

/*
* Loader css
*/

.loader {
  border-top-color: #3498db;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*
* Loader css
*/

.common-background {
  background-color: #d9d9d9;
}

.btn-custom-color {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  border-radius: 0.25rem;
  --bg-opacity: 1;
  background-color: grey;
  --text-opacity: 1;
  color: #fff;
  font-size: 0.875rem;
  font-weight: 700;
  white-space: nowrap;
}

.btn-custom-color:hover,
.btn-custom-color:focus {
  --bg-opacity: 1;
}

/*KD -- 03/11/2022*/
/* .dropdown {
  top: 100%;
} */
.dropdown .dropdown {
  top: 0;
  left: 100%;
}

.main-navbar>div,
.dropdown li div {
  position: relative;
}

.dashboardCard-icon {
  flex: 64px 0 0;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.selection.dropdown.form-select {
  position: relative;
}

.selection.dropdown.form-select .dropdown.icon {
  right: 0;
  margin: 0;
  top: 0;
  left: auto;
  float: none;
}

.form-error {
  font-size: 12px !important;
  line-height: 16px !important;
}

.mobileMenuDropdown {
  right: 0;
  transform: inherit !important;
  top: 65px !important;
}

@media only screen and (min-width: 768px) {
  .inner-menuIcon {
    transform: rotate(-90deg);
  }
}

.no-border {
  border: none !important;
}

.active-stream-border {
  border: 1px green solid;

  animation: blink 1s;
  animation-iteration-count: infinite;
}

@keyframes blink {
  50% {
    border-color: #fff;
  }
}

.auth-buttons {
  background-color: #4F6B69;
}

.auth-buttons:hover {
  background-color: #4F6B69;
}

.min-w-10 {
  min-width: 10rem;
}

.multiselect-input a.ui.label {
  margin-right: 10px !important;
}

.custom-edit-class .menu {
  bottom: 100%;
  top: auto !important;
}

.date-picker-main {
  position: relative;
}

.date-picker-main i {
  position: absolute;
  top: 13px;
  right: 20px;
  cursor: pointer;
}


/* Slider css */
.slidecontainer {
  width: 100%;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 10px;
  background: #d3d3d3;
  background: linear-gradient(to right, green 0%, orange 50%, red 100%);
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  /* width: 25px;
  height: 25px;
  background: black;
  cursor: pointer; */
  width: 4em;
  height: 4em;
  background: linear-gradient(-45deg, black 20%, black 50%, black 50%, black calc(50% + 1px), rgba(0, 0, 0, 0) calc(50% + 1px)) no-repeat content-box;
  cursor: pointer;
  transform: translateY(-1.25em) rotate(30deg) skewY(30deg) scaleX(0.86603);
  border: solid 1.5em transparent;
  box-sizing: border-box;
}

.slider::-moz-range-thumb {
  /* width: 25px;
  height: 25px;
  background: white;
  cursor: pointer; */
  width: 4em;
  height: 4em;
  background: linear-gradient(-45deg, black 20%, black 50%, black 50%, black calc(50% + 1px), rgba(0, 0, 0, 0) calc(50% + 1px)) no-repeat content-box;
  cursor: pointer;
  transform: translateY(-1.25em) rotate(30deg) skewY(30deg) scaleX(0.86603);
  border: solid 1.5em transparent;
  box-sizing: border-box;
}

.risk0::-moz-range-thumb {
  transform: translateY(-1.25em) translateX(-2em) rotate(30deg) skewY(30deg) scaleX(0.86603);
}

.risk50::-moz-range-thumb {
  transform: translateY(-1.25em) rotate(30deg) skewY(30deg) scaleX(0.86603);
}

.risk100::-moz-range-thumb {
  transform: translateY(-1.25em) translateX(2em) rotate(30deg) skewY(30deg) scaleX(0.86603);
}

.risk0::-webkit-slider-thumb {
  transform: translateY(-1.25em) translateX(-2em) rotate(30deg) skewY(30deg) scaleX(0.86603);
}

.risk50::-webkit-slider-thumb {
  transform: translateY(-1.25em) rotate(30deg) skewY(30deg) scaleX(0.86603);
}

.risk100::-webkit-slider-thumb {
  transform: translateY(-1.25em) translateX(2em) rotate(30deg) skewY(30deg) scaleX(0.86603);
}

.vjs-value {
  word-break: break-all;
  white-space: pre;
}

.multi-files .left-0 {
  left: 10px !important;
}

.multi-files .top-0 {
  top: -7px !important;
}

#quill-container {
  max-height: 500px;
  overflow: auto;
  height: auto;
}
/* purgecss end ignore */