/* purgecss start ignore */

@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;500;600;700;800;900&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;500;600;700;800;900&display=swap');


.loginlogo {
  background: #4F6B69;
  border: 1px solid #4F6B69;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.formdiv h3 {
  font-size: 24px;
  font-weight: 500;
}

.text-dark-grey {
  color: #5A5A5F;
}

.dark-grey-border {
  border-color: #B6B6BB;
}

.formdiv input::placeholder {
  color: #030229 !important;
  opacity: 0.3;
}

.formdiv input:-ms-input-placeholder {
  color: #030229 !important;
  opacity: 0.3;
}

.formdiv input::-ms-input-placeholder {
  color: #030229 !important;
  opacity: 0.3;
}

.searchinput::placeholder {
  color: #D2D2D2 !important;
}

.searchinput:-ms-input-placeholder {
  color: #D2D2D2 !important;
}

.searchinput::-ms-input-placeholder {
  color: #D2D2D2 !important;
}

.text-green {
  color: #769144;
}

.leftsidebar {
  width: 220px;
  background-color: #4F6B69;
  transition: 0.6s ease;
}

/* .navlinks a {
  color: #fff;
} */

.submenus a:before,
.submenus span:before {
  content: "-" !important;
  position: relative;
  left: -5px;
}

/* .navlinks div:nth-last-child(2) {
  padding-top: 40px;
  border-top: 1px solid #6A833D;
  margin-top: 30px;
} */


.righthdr {
  box-shadow: 0px 1px 4px rgba(118, 145, 69, 0.25);
  margin: 0px -27px;
}

.maincontent_sec {
  background: #F5F5F2;
  transition: 0.6s ease;
}

.rightside_hdr ul li {
  padding: 0px 5px;
}

.rightside_hdr li.righthdrlink a,
.rightside_hdr li button {
  width: 35px;
  display: flex;
  border-radius: 30px;
  height: 35px;
  align-items: center;
  justify-content: center;
  padding: 7px;
}

.lightbggreen {
  background: #EDF0E7;
}

.sidebarshow {
  width: 220px;
}

.sidebarhide {
  width: 0px;
}

.contentwithsidebar {
  width: calc(100% - 220px);
}

/* li.username span {
  color: #6D6E6A;
} */

.darkgreytext {
  color: #030229;
}

.lightgreytext {
  color: #717171;
}

.breadcrumbs_arrows {
  font-size: 8px;
}

.lightbggreen {
  background: #F1F4EC;
}

.textgreen {
  color: #4F6B69;
}

.bggreen {
  background: #F1F4EC;
}

.bggreen-300 {
  background: #ECF7D2;
}

body .bgdarkgreen,
.tooltipbgdarkgreen {
  background: #4F6B69;
}

.bgdarkgreen:hover,
.hoverbgdarkgreen:hover {
  background: var(--button-color);
  color: #fff;
}

.hoverbordergreen:hover,
.bgdarkgreen:hover {
  border-color: var(--button-color);
}

.textdarkgreen {
  color: #4F6B69;
}

.searchinput {
  background: url(/images/searchicon.svg);
  background-repeat: no-repeat;
  background-position: 16px;
  background-size: 20px;
}

.filtertext {
  color: #333E4D;
}

/* .active, */
.navlinks a:hover, .navlinks a.active {
  color: #CDF76B !important;
}

.lightgrey_border {
  border-color: rgba(3, 2, 41, 0.1) !important;
}

.border-green {
  border-color: #4F6B69;
}

tr:nth-child(even):not(.no-style) {
  background-color: #F1F4EC;
}

tr:nth-child(od) {
  background-color: #fff;
}

.nunitofont {
  font-family: 'Nunito', sans-serif
}

.dropdown_widget {
  transform: translate(0px, 50px);
}

.assigntooltip_div:hover .assigntooltip-content {
  opacity: 1;
}

.unassigntooltip_div:hover .unassigntooltip-content {
  opacity: 1;
}

.tooltip-content {
  transform: translate(-120px, -4px);
  font-size: 10px;
  padding: 4px 10px;
  top: -6px;
}

.tooltip-content:before {
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 6px solid #4F6B69;
  content: "";
  right: -6px;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
}

.rotate-45 {
  --transform-rotate: 45deg;
  transform: rotate(45deg);
}

.group:hover .group-hover\:flex {
  display: flex;
}

.tooltip-right-arrow {
  right: -4px;
}

.tooltip-top-arrow {
  top: -4px;
}

.rounded-medium {
  border-radius: 8px;
}

.text-greyishblack {
  color: #030229;
}

.textgreycolor {
  color: #494949;
}

.greybottomborder {
  border-bottom: 1px solid #EEF0EC;
}

.lightbggrey {
  background: #F8FAF6;
}

body .selection.dropdown.form-select {
  position: relative;
  background: #F8FAF6 !important;
  border: 0 !important;
  box-shadow: none !important;
  padding: 18px 15px 0;
  border: 0px;
}

body .selection.dropdown.form-select i.dropdown.icon {
  height: 100%;
  padding: 9px 10px;
  color: #4F6B69;
  font-size: 22px;
  display: flex;
  align-items: center;
}

.transparentbtn {
  border: 0.7px solid rgba(0, 0, 0, 0.6);
  color: #494949;
}

body .selection.dropdown.form-select input {
  height: 48px;
  color: #030229;
}

body .selection.dropdown.form-select .text.default {
  color: #030229 !important;
  opacity: 0.3;
}

body .ui.selection.dropdown .menu {
  border-color: #4F6B69 !important;
}

form.formdiv .form-label {
  color: #5A5A5F;
}

.loginfields input {
  background: #fff;
  border: 1px solid #B6B6BB;
  font-size: 15px;
  color: #5A5A5F !important;
}

.formdiv .auth-buttons {
  font-size: 18px;
}

form.formdiv {
  width: 370px;
}

.input-focus:focus {
  border-color: #4F6B69 !important;
  box-shadow: none;
}

.tabsection nav a:first-child {
  border-radius: 4px 0px 0px 4px;
}

.tabsection nav a:last-child {
  border-radius: 0px 4px 4px 0px;
}

body .multiselect-input a.ui.label i.delete.icon {
  right: 13px !important;
}

body .multiselect-input a.ui.label {
  background: #4F6B69;
  box-shadow: none !important;
  border-radius: 3.68001px;
  font-size: 12px !important;
  font-weight: 500;
  padding: 9px 30px 9px 9px !important;
  color: #fff;
  text-transform: uppercase;
  position: relative;
  top: -11px;
}

body .selection.dropdown.form-select.multiselect-input .text {
  height: 100% !important;
  padding: 16px 12px !important;
  margin: 0 !important;
  display: flex;
  align-items: center;
}

body .ui.multiple.search.dropdown.multiselect-input input.search {
  position: relative !important;
  height: auto;
  margin-top: -37px !important;
  min-height: 30px;
  top: 10px;
  left: -10px;
}

.bgyellow {
  background: #FBF2BE;
}

.textgrey-200 {
  color: #4C4C4C;
}

.paginationtext:last-child {
  background: transparent;
  margin-left: 20px;
  width: auto;
}

.blanktablediv {
  background: #f8f9f5;
  border-top: 15px solid #fff !important;
}

.ui.fluid.search.dropdown.selection.multiple.form-select.multiselect-input {
  height: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  min-height: 48px;
}

.bggreen-200 {
  background: rgba(125, 168, 42, 0.46);
}

.returnstable {
  background-color: #fff !important;
}

.buttonlink {
  font-weight: 500 !important;
  font-size: 12px !important;

}

.paginationtext:first-child {
  background: transparent;
  width: auto;
  margin-right: 20px;
}

.paginationtext:first-child:after,
.paginationtext:last-child:after {
  content: "page";
  padding-left: 6px;
}

nav.breadcrumbs li a,
nav.breadcrumbs li span {
  color: #717171;
}

nav.breadcrumbs li:last-child a,
nav.breadcrumbs li:last-child span {
  color: #030229;
}

.organization_table input {
  background: #fff;
  border: 1px solid #eee;
  border-radius: 0;
}

.organization_table tr {
  background: transparent;
  border: 0;
}

.lightblacktext {
  color: rgba(0, 0, 0, 0.4);
}

.texteditor .ql-toolbar.ql-snow,
.texteditor .ql-container.ql-snow {
  border-color: #f8faf6;
}

.texteditor .ql-toolbar.ql-snow .ql-formats {
  margin-right: 10px;
}

.texteditor .ql-editor p {
  color: #000;
  line-height: 28px;
  font-size: 14px;
}

.texteditor .ql-editor a {
  color: #000;
}

.ui.selection.dropdown .menu>.item {
  color: rgba(0, 0, 0, .87) !important;
}

.ui.selection.dropdown .menu>.item:hover {
  background: #F1F4EC !important;

}

.swal2-container .swal2-icon {
  margin-top: 0px;
  border: 0;
  margin-bottom: 10px;
  width: 100px;
  height: 100px;
}

.swal2-html-container {
  color: #000;
  line-height: 35px;
}

.swal2-popup.swal2-modal {
  border-radius: 4px;
  padding: 40px 30px;
}

.swal2-container .swal2-actions button {
  margin: 0px 4px;
  font-size: 14px;
  border-radius: 4px;
  height: 45px;
  padding: 10px 30px;
  font-weight: 600;
  border-radius: 4px;
  min-width: 144px;
}

.swal2-container .swal2-actions button.swal2-cancel {
  background: #FFFFFF;
  border: 0.7px solid rgba(0, 0, 0, 0.6);
  color: rgba(73, 73, 73, 0.8);

}

.swal2-container .swal2-actions button.swal2-confirm {
  background: #4F6B69;
  border: 0.7px solid #4F6B69;
  color: #fff;
}

.swal2-container .swal2-content {
  padding: 0;
}

.swal2-container button.swal2-close {
  width: 20px;
  height: 20px;
  border: 2px solid #000000;
  border-radius: 30px;
  color: #000 !important;
  font-weight: bold;
  font-size: 17px;
  top: 11px;
  right: 11px;
  padding-top: 2.35px;
  box-shadow: none !important;
}

.boxshadow {
  box-shadow: 0px 4px 14px rgba(118, 145, 69, 0.2)
}

.dashboardicons {
  width: 56px;
  height: 56px;
}

.dashboardtext {
  width: calc(100% - 56px);
}

.borderhover:hover {
  border: 1.5px solid #4F6B69;
}

.borderhover {
  border: 1.5px solid transparent;
}

.gridbox:last-child {
  margin-bottom: 0px;
}

.minequalheight {
  min-height: calc(100% - 30px);
}

.selection_box .selection.dropdown.form-select {
  background: #fff !important;
}

.adressfield .ui.fluid.search.selection.dropdown.form-select,
.adressfield input {
  background-image: url(/images/searchicon.svg) !important;
  background-size: 20px !important;
  background-position: 14px !important;
  background-repeat: no-repeat !important;
  padding-left: 40px;
  padding-right: 30px;
}

.adressfield .search {
  padding-left: 40px !important;
}

.walletconnect-qrcode__base {
  background: rgba(0, 0, 0, 0.3) !important;
}

.seladressdiv .ui.selection.dropdown .menu {
  border: 0px;
}

.seladressdiv .ui.selection.dropdown .menu .item {
  border-color: #EEF0EC !important;
}

.swal2-popup.swal2-toast.swal2-icon-error {
  background: #fff2f2;
}

.swal2-popup.swal2-toast.swal2-icon-error button.swal2-close,
.swal2-popup.swal2-toast.swal2-icon-success button.swal2-close {
  display: none !important;
}

.swal2-popup.swal2-toast.swal2-icon-error div#swal2-content,
.swal2-popup.swal2-toast.swal2-icon-success div#swal2-content {
  padding-top: 0px;
  margin-left: 0px !important;
  font-size: 14px !important;
  color: #1B2430;
}

.swal2-popup.swal2-toast.swal2-icon-error .swal2-content,
.swal2-popup.swal2-toast.swal2-icon-success .swal2-content {
  margin-left: 0px !important;
}

.swal2-popup.swal2-toast.swal2-icon-error span.swal2-x-mark-line-left,
.swal2-popup.swal2-toast.swal2-icon-error span.swal2-x-mark-line-right,
.swal2-popup.swal2-toast.swal2-icon-success span.swal2-x-mark-line-left,
.swal2-popup.swal2-toast.swal2-icon-success span.swal2-x-mark-line-right {
  height: 3px !important;
}

.swal2-popup.swal2-toast.swal2-icon-success .swal2-success-ring {
  display: none;
}

.swal2-icon.swal2-success [class^=swal2-success-line] {
  background: #4F6B69 !important;
}

.wallettable tr:hover .showonhover {
  opacity: 1;
}

.adressfield i.dropdown.icon:before {
  content: "\F0D7";
}

.adressfield i.dropdown.icon {
  font-family: 'Dropdown';
  color: #4F6B69;
  font-size: 22px !important;
}

i.dropdown.icon.inputdropdown_icon {
  position: absolute;
  right: 10px;
  bottom: 13px;
  width: 26px;
  font-style: normal;
}

.adressfield {
  position: relative;
}

.mx-datepicker-popup .mx-calendar-content tr {
  background: #fff;
}

body .mx-calendar-content .cell.active div {
  color: #fff;

}

.datepickerdiv input.mx-input {
  background: #F8FAF6;
  border: 0;
  box-shadow: none;
  border-radius: 0.25rem;
  height: 3rem;
}

.datepickerdiv .mx-datepicker {
  width: 100%;
}

body .mx-datepicker-popup .mx-calendar-content .cell.active {
  background: #4F6B69;
  color: #fff !important;
}

body .mx-datepicker-popup .mx-calendar-content .cell:hover {
  background: #F1F4EC;
}

body .mx-datepicker-popup .mx-calendar-content .cell {
  border-radius: 100%;
}

body .datepickerdiv i.mx-icon-calendar {
  background-image: url(/images/datepickericon.svg);
  background-size: contain;
  background-position: right;
}

body .datepickerdiv i.mx-icon-calendar svg {
  opacity: 0;
}

.darkgreen_bg {
  background-color: #4F6B69;
}

.green_bg_100 {
  background-color: #CDF76B;
}

.green_bg_200 {
  background-color: #ACCD6F;
}

.pink_bg {
  background-color: #DF3FB2;
}

.purple_bg {
  background-color: #7983D0;
}

.blue_bg {
  background-color: #74C6D9;
}

.greenborder {
  border-color: #6A833D;
}

.seladressdiv .menu {
  transform: scale(1.056);
  left: 0px !important;
  margin-top: 20px !important;
  box-shadow: none !important;
  background: #fff !important;
  border-radius: 0.375rem !important;
  margin: 20px 0 !important;
}

.seladressdiv .ui.selection.dropdown .menu .item {
  background: transparent !important;
  border: 0;
  border-bottom: 1px solid #EEF0EC !important;
  padding: 20px 15px !important;
  color: rgba(3, 2, 41, 0.3) !important;
}

body .selassets .selection.dropdown.form-select {
  background-color: #4F6B69 !important;
  color: #fff !important;
  height: 38px !important;
  display: flex;
  padding-top: 0px;
  align-items: center;
  background-image: url(/images/dropdownicon.svg) !important;
  background-repeat: no-repeat !important;
  background-position: 91% !important;
}

body .selassets .selection.dropdown.form-select i.dropdown.icon {
  display: none;
}

body .selassets .selection.dropdown.form-select input {
  top: -4px;
}

body .selassets .ui.active.search.dropdown input.search:focus+.text {
  color: #fff !important;
}

body .selassets .selection.dropdown.form-select .text {
  color: #fff !important;
  opacity: 1 !important;
  font-size: 16px;
  font-family: 'Nunito', sans-serif;
  font-weight: 600;
}

/* .submenus {
  display: none !important;
} */

.apexcharts-menu.apexcharts-menu-open {
  min-width: 140px;
}

.bannerimg {
  background-image: url(/images/greenbg.jpg);
  background-size: cover;
}

.slick-arrow {
  display: none !important;
}

.blogslider .slick-dots li {
  width: 15px;
  margin: 0;
}

.blogslider .slick-dots li.slick-active button:before,
.blogslider .slick-dots li:hover button:before {
  opacity: 1;
  color: #4F6B69;
}

.blogslider .slick-dots li button:before {
  opacity: 0.1;
}

.line-clamp-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.line-clamp-3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.menu-bg-color {
  background-color: var(--primary-color);
}

.menu-stroke-icon-color {
  stroke: var(--menu-font-color);
}

.sidebar-icon-color {
  fill: var(--menu-font-color);
}

.navlinks a.active svg path.sidebar-icon-color,
.navlinks a:hover svg path.sidebar-icon-color {
  fill: #CDF76B !important;
}

.navlinks a.active svg path.menu-stroke-icon-color,
.navlinks a:hover svg path.menu-stroke-icon-color {
  stroke: #CDF76B !important;
}

.portfolio-popup {
  max-height: 33rem;
  overflow: auto;
}

/* .tabminheight {
  min-height: 480px;
} */

@media (max-width:1440px) {
  .swal2-container .swal2-icon {
    width: 80px;
    height: 80px;
  }

  .swal2-html-container {
    font-size: 16px;
    line-height: 30px;
  }
}

@media (max-width:1280px) {
  .loginlogo img {
    width: 300px;
  }

  body .mobsmallfont {
    font-size: 7px;
  }

  .unassign_client {
    right: 0;
  }

  body .tooltip-top-arrow {
    top: auto;
    bottom: -4px;
  }

  body .tooltip-right-arrow {
    top: 13px;
  }

  .rounded-medium {
    border-radius: 4px;
  }

  .loginfields input {
    font-size: 13px;
  }

  .swal2-container .swal2-icon {
    width: 60px;
    height: 60px;
  }

  .dashboardtext {
    width: calc(100% - 46px);
  }

  .dashboardicons {
    width: 46px;
    height: 46px;
  }
}

@media (min-width:991px) {
  .font18 {
    font-size: 18px !important;
  }

  .font20 {
    font-size: 20px !important;
  }

  .font16 {
    font-size: 16px !important;
  }
}

@media (max-width:991px) {
  .breadcrumbs li {
    margin-left: 8px !important;
  }

  nav.breadcrumbs li a {
    font-size: 9px;
  }

  .breadcrumbs li:first-child {
    margin-left: 0px !important;
  }

  .loginfields input {
    font-size: 11px;
  }

  form.formdiv {
    width: 80%;
    padding: 40px 0;
  }

  .formdiv h3 {
    font-size: 22px;
    margin-bottom: 0;
  }

  .auth-buttons {
    font-size: 16px !important;
  }

  .searchinput {
    background-position: 9px;
    background-size: 14px;
  }

  body .selection.dropdown.form-select .text {
    font-size: 14px !important;
    top: -2px;
  }

  body .selection.dropdown.form-select .item {
    font-size: 12px;
  }

  body .selection.dropdown.form-select i.dropdown.icon {
    padding: 16px 10px;
    font-size: 15px;
  }
}

@media (max-width:767px) {
  body .selection.dropdown.form-select i.dropdown.icon {
    padding: 16px 3px;
  }

  body .selection.dropdown.form-select .text {
    font-size: 12px !important;
  }

  .adressfield .ui.fluid.search.selection.dropdown.form-select,
  .adressfield input {
    background-size: 15px !important;
    padding-left: 30px !important;
    padding-right: 15px;
  }

  .adressfield i {
    font-size: 23px;
  }

  .seladressdiv .menu {
    transform: scale(1.05);
  }

  .loginlogo img {
    width: 240px;
    padding: 10px;
  }

  .formheight {
    min-height: calc(100vh - 140px);
  }

  .formdiv {
    padding: 0px 20px;
  }

  body .leftsidebar {
    position: absolute !IMPORTANT;
    z-index: 99;
  }

  .mobsidebarshow {
    width: 85%;
  }

  .mobsidebarhide {
    width: 0
  }

  .maincontent_sec {
    width: 100%;
  }

  .righthdr .rightside_hdr {
    display: none;
  }

  .leftside_hdr {
    margin-left: auto;
    width: 100%;
  }

  .mobcontentsidebar .leftside_hdr img {
    margin-left: auto;
  }

  .formdiv h3 {
    font-size: 18px;
  }

  body .smallfontsize {
    font-size: 11px;
  }

  body .multiselect-input a.ui.label {
    font-size: 10px !important;
  }

  .swal2-html-container {
    font-size: 14px;
    line-height: 24px;
  }

  .swal2-popup.swal2-modal {
    padding: 20px 15px;
    max-width: 80%;
  }

  .swal2-container .swal2-actions button {
    min-width: auto;
    padding: 0px 13px;
    font-size: 12px;
    margin-bottom: 10px;
  }

  .righthdr {
    margin: 0px -15px;
  }
}

@media (max-width:480px) {
  .swal2-popup.swal2-modal {
    max-width: 100%;
  }

  .loginlogo img {
    width: 160px;
    padding: 10px;
  }

  .seladressdiv .menu {
    transform: scale(1.08);
  }
}

@media (max-width:360px) {
  body .ui.multiple.search.dropdown.multiselect-input input.search {
    top: 20px;
  }

  body .selection.dropdown.form-select {
    padding-bottom: 15px;
  }
}

/* purgecss end ignore */