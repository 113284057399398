@import url('https://fonts.googleapis.com/css2?family=Sora:wght@100;300;400;500;600;700;800&display=swap');

@import "./reset.css";
@import "./buttons.css";
@import "./form.css";
@import "./custom.css";
@import "./new-custom.css";

@tailwind base;
/* @import "tailwindcss/base"; */

/* Components */
@tailwind components;
/* @import "tailwindcss/components"; */

/* Utilities */
@tailwind utilities;
/* @import "tailwindcss/utilities"; */

* {
    color: #1B2430;
    font-family: 'Poppins', sans-serif;
}